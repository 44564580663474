<template>
  <b-card>
    <section>
      <div class="d-flex justify-content-start flex-wrap">
        <b-button
          variant="adn"
          data-action-type="new"
          class="mr-25 mb-1"
          @click="
            (v) => {
              openModal();
            }
          "
        >
          <feather-icon
            icon="UploadIcon"
            class="mr-25"
          />
          {{ $t('uploadEmployeesFromExcelFile') }}
        </b-button>

        <b-button
          variant="outline-adn"
          class="ml-50 mb-1"
          data-action-type="new"
          @click="
            (v) => {
              downloadExcelSheet();
            }"
        >
          <feather-icon
            icon="DownloadIcon"
            class="mr-25"
          />
          {{ $t('downloadExcelSheet') }}
        </b-button>
      </div>
    </section>

    <b-modal
      ref="edit-modal"
      no-close-on-backdrop
      centered
      size="lg"
      hide-footer
      :title="$t('exportNewEmployees')"
    >
      <g-form @submit="uploadItems">
        <b-row>
          <b-col cols="12">
            <div>
              <input
                accept=".xlsx, .xls"
                type="file"
                class="excel-upload-input"
                @change="handleFileUpload( $event )"
              >
              <b-alert
                v-if="file !== ''"
                show
                fade
                class="mt-2 text-center"
                variant="success"
              >
                <div class="alert-body">
                  <span>{{ $t('importSuccsefly') }} {{ file.name }}</span>
                </div>
              </b-alert>
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col
            cols="12"
            class="d-flex justify-content-end"
          >
            <b-button
              v-permission="$route.meta.permission"
              class="mx-1"
              type="submit"
              variant="gradient-primary"
              data-action-type="save"
            >
              {{ $t('save') }}
            </b-button>
            <b-button
              class="mx-1"
              variant="outline-primary"
              @click="closeModal"
            >
              {{ $t('Close') }}
            </b-button>
          </b-col>
        </b-row>

      </g-form>
    </b-modal>

    <b-row>
      <b-button
        v-permission="'viewEmployees'"
        class="btn-filter"
        variant="adn"
        @click=" () => {
          showFilter = !showFilter;
          ResetSelection();
        }"
      >
        {{ !showFilter ? this.$t('showFilter') : this.$t('hideFilter') }}
        <feather-icon
          icon="FilterIcon"
          class="danger"
        />
      </b-button>
    </b-row>

    <b-row
      v-if="showFilter"
      class="filter-section"
    >
      <b-col
        lg="3"
        md="4"
        sm="6"
        xs="12"
      >
        <g-field
          id="employeeCode"
          v-silent-search
          :value.sync="filter.employeeCode"
          label-text="code"
          name="employeeCode"
          @keydown.enter="() => { getItems();}
          "
        />
      </b-col>

      <b-col
        lg="3"
        md="4"
        sm="6"
        xs="12"
      >
        <g-field
          id="employeeName"
          v-silent-search
          :value.sync="filter.employeeName"
          label-text="employeeName"
          name="employeeName"
          @keydown.enter="() => { getItems(); }
          "
        />
      </b-col>

      <b-col
        lg="3"
        md="4"
        sm="6"
        xs="12"
      >
        <g-field
          :value.sync="filter.departmentId"
          :dir="isRight ? 'rtl' : 'ltr'"
          label-text="department"
          field="select"
          :options="departments"
          :label="isRight ? 'arabicName' : 'englishName'"
          @change="() => getItems()"
          @input="() => { if(!filter.departmentId) getItems() }"
        />
      </b-col>

      <b-col
        lg="3"
        md="4"
        sm="6"
        xs="12"
      >
        <g-field
          :value.sync="filter.divisionId"
          :dir="isRight ? 'rtl' : 'ltr'"
          label-text="division"
          field="select"
          :options="divisions"
          :label="isRight ? 'arabicName' : 'englishName'"
          @change="() => getItems()"
          @input="() => { if(!filter.divisionId) getItems() }"
        />
      </b-col>

      <b-col
        lg="3"
        md="4"
        sm="6"
        xs="12"
      >
        <g-field
          :value.sync="filter.jobId"
          :dir="isRight ? 'rtl' : 'ltr'"
          label-text="job"
          field="select"
          :options="jobs"
          :label="isRight ? 'arabicName' : 'englishName'"
          @change="() => getItems()"
          @input="() => { if(!filter.jobId) getItems() }"
        />
      </b-col>

      <b-col
        lg="3"
        md="4"
        sm="6"
        xs="12"
      >
        <g-field
          id="mobileNumber"
          v-silent-search
          :value.sync="filter.mobileNumber"
          label-text="mobile"
          name="mobileNumber"
          @keydown.enter="() => { getItems();}
          "
        />
      </b-col>
    </b-row>

    <b-row class="mb-1 mt-1">
      <b-col
        md="12"
        class="list-buttons d-flex align-items-center justify-content-end mb-1 mb-md-0"
      >
        <b-button
          v-permission="'addEmployees'"
          variant="primary"
          data-action-type="new"
          @click="
            (v) => {
              $router.push({ name: 'employee-new' });
            }
          "
        >
          {{ $t("new") }}
        </b-button>
        <vue-excel-xlsx
          :data="items"
          :columns="excelColumns"
          :filename="this.$route.name"
          :sheetname="'xlsxSheet'"
          class="btn btn-relief-success ml-1 p-0 ptn-sm"
        >
          <img
            src="@/assets/images/icons/xls.jpg"
            alt=""
            style="height: 39px; width: auto"
          >
        </vue-excel-xlsx>
        <b-button
          variant="relief-danger"
          class="ml-1 p-0 ptn-sm"
          @click="printDocument"
        >
          <img
            src="@/assets/images/icons/pdf.jpg"
            alt=""
            style="height: 39px; width: auto"
          >
        </b-button>
      </b-col>
    </b-row>

    <g-table
      ref="employees-table"
      :items="items"
      :columns="tableColumns"
      :noAction="true"
      perPage="25"
      :totalRows="totalRows"
      :createButton="{ visiable: true }"
      :excelButton="{ visiable: false }"
      :pdfButton="{ visiable: false }"
      :searchInput="{ visiable: false }"
    >
      <template #fullName="{ item }">
        <div class="d-table">
          <b-avatar
            size="40"
            :src="`${baseUrl}${item.imageUrl}`"
            variant="primary"
          />
          {{ item.fullName }}
        </div>
      </template>

      <template
        v-if="!hideActions"
        #actions="{ item }"
      >
        <div class="text-nowrap">
          <b-button
            v-b-tooltip.hover.top="$t('edit')"
            v-permission="'editEmployees'"
            data-action-type="edit"
            variant="custom"
            class="btn-icon"
            size="sm"
            @click="edit(item)"
          >
            <feather-icon
              :id="`invoice-row-${item.id}-preview-icon`"
              icon="EditIcon"
              class="mx-25 clickable"
            />
          </b-button>
          <b-button
            v-b-tooltip.hover.top="$t('delete')"
            v-permission="'deleteEmployees'"
            data-action-type="delete"
            variant="flat-danger"
            class="btn-icon"
            size="sm"
            @click="remove(item)"
          >
            <feather-icon
              :id="`invoice-row-${item.id}-delete-icon`"
              icon="TrashIcon"
              stroke="red"
              class="danger"
            />
          </b-button>
        </div>
      </template>
    </g-table>
  </b-card>
</template>
<script src="https://cdnjs.cloudflare.com/ajax/libs/jspdf/1.5.3/jspdf.min.js"></script>
<script src="@/pages/Shared/html2canvas.js"></script>
<script>
import saveAs from 'file-saver';
import GTable from '@/pages/Shared/Table.vue';
import { rows } from '@/libs/acl/Lookups';

export default {
  components: {
    GTable,
  },
  data() {
    return {
      fileFromBE: '',
      file: '',
      searchQuery: '',
      totalRows: 0,
      currentPage: 1,
      isTableBusy: false,
      perPage: 25,
      filterOn: [],
      isSortDirDesc: false,
      sortDirection: 'asc',
      showFilter: false,
      pathName: this.$route.name,
      items: [],
      hideActions: false,
      stages: [],
      classRooms: [],
      rows: rows,
      filter: {
        employeeCode: null,
        employeeName: null,
        departmentId: null,
        divisionId: null,
        jobId: null,
        mobileNumber: null
      },
      jobs: [],
      departments:[],
      divisions: []
    };
  },
  computed: {
    tableColumns() {
      return [
        {
        key: 'code',
        field: 'code',
        label: this.$t('code'),
        sortable: true
        },
        {
          key: 'fullName',
          field: 'fullName',
          label: this.$t('name'),
          sortable: true,
        },
        {
          key: this.isRight ? 'departmentNameAr' : 'departmentNameEn',
          field: this.isRight ? 'departmentNameAr' : 'departmentNameEn',
          label: this.$t('department'),
          sortable: true,
        },
        {
          key: this.isRight ? 'divisionNameAr' : 'divisionNameEn',
          field: this.isRight ? 'divisionNameAr' : 'divisionNameEn',
          label: this.$t('division'),
          sortable: true,
        },
        {
          key: this.isRight ? 'jobNameAr' : 'jobNameEn',
          field: this.isRight ? 'jobNameAr' : 'jobNameEn',
          label: this.$t('job'),
          sortable: true,
        },
        {
          key: 'mobileNumber',
          field: 'mobileNumber',
          label: this.$t('mobile'),
          sortable: true,
        },
        { key: 'actions' },
      ];
    },
    excelColumns() {
      return [
        { key: 'code', field: 'code', label: this.$t('code') },
        { key: 'arabicName', field: 'arabicName', label: this.$t('arabicName') },
        { key: 'englishName', field: 'englishName', label: this.$t('englishName') },
        { key: 'hireDate', field: 'hireDate', label: this.$t('hireDate') },
        { key: 'genderNameAr', field: 'genderNameAr', label: this.$t('gender') },
        { key: 'basicSalary', field: 'basicSalary', label: this.$t('basicSalary') },
        { key: 'mobileNumber', field: 'mobileNumber', label: this.$t('mobile') },
        { key: 'email', field: 'email', label: this.$t('email') },
        { key: 'passportNumber', field: 'passportNumber', label: this.$t('passportNumber') },
        { key: 'passportExpirationDate', field: 'passportExpirationDate', label: this.$t('passportExpirationDate') },
        { key: 'residenceNumber', field: 'residenceNumber', label: this.$t('residenceNumber') },
        { key: 'residenceExpirationDate', field: 'residenceExpirationDate', label: this.$t('residenceExpirationDate') },
        { key: 'departmentNameAr', field: 'departmentNameAr', label: this.$t('department') },
        { key: 'divisionNameAr', field: 'divisionNameAr', label: this.$t('division') },
        { key: 'qualificationNameAr', field: 'qualificationNameAr', label: this.$t('qualification') },
        { key: 'jobNameAr', field: 'jobNameAr', label: this.$t('job') },
        { key: 'religionNameAr', field: 'religionNameAr', label: this.$t('religion') },
        { key: 'maritalStatusNameAr', field: 'maritalStatusNameAr', label: this.$t('maritalStatus') },
        { key: 'nationalityNameAr', field: 'nationalityNameAr', label: this.$t('nationality') },
        { key: 'jobNumber', field: 'jobNumber', label: this.$t('jobNumber') },
        { key: 'activeStatus', field: 'activeStatus', label: this.$t('employeeWorkSituation') },
        { key: 'accountNameAr', field: 'accountNameAr', label: this.$t('accountName') },
        { key: 'isDepartmentStatus', field: 'isDepartmentStatus', label: this.$t('isEmployeeBelongsToManagement') },
        { key: 'isSharedStatus', field: 'isSharedStatus', label: this.$t('isEmployeeShared') },
        { key: 'affiliateCount', field: 'affiliateCount', label: this.$t('affiliateCount') },
        { key: 'totalBenefits', field: 'totalBenefits', label: this.$t('totalBenefits') },
        { key: 'totalDeductions', field: 'totalDeductions', label: this.$t('totalDeductions') },
      ]
    }
  },
  mounted() {
    this.getItems();
    this.loadLookups();
  },
  methods: {
    hide() {
      this.hideActions = true
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    refreshItems() {
      this.$refs['employees-table'].refreshTable();
    },
    getItems() {
      var params = this.getFilterObj(this.filter);
      this.get({ url: `employees?${params}` }).then((data) => {
         this.items = data;
         this.totalRows = data.length;
         this.items.forEach(element => {
            element.fullName = this.isRight ? element.arabicName : element.englishName;
            element.imageUrl = element.imageUrl ? element.imageUrl : 'employee_default_image.png'
            element.hireDate = this.getDate(element.hireDate);
            element.passportExpirationDate = element.passportExpirationDate ? this.getDate(element.passportExpirationDate) : null;
            element.residenceExpirationDate = element.residenceExpirationDate ? this.getDate(element.residenceExpirationDate) : null;
            element.activeStatus = element.isSuspended ? this.$t('suspended') : this.$t('unSuspended');
            element.isDepartmentStatus = element.isDepartment ? this.$t('yes') : this.$t('no');
            element.isSharedStatus = element.isShared ? this.$t('yes') : this.$t('no');
         });
      });
    },
    remove(item) {
      this.confirmAction(
        {
          text: this.$t('Are you sure you want to delete?'),
        },
        () => {
          this.delete({
            url: 'employees',
            id: item.id,
          }).then(() => {
            this.doneAlert({ text: this.$t('deletedSuccessfully') });
            this.getItems();
          });
        }
      );
    },
    edit(item) {
      this.$router.push({
        name: 'employee-edit',
        params: { id: item.id },
      });
    },
    closeModal() {
      this.$refs['edit-modal'].hide();
      if (this.file) {
        this.getItems();
      }
    },
    openModal() {
      this.$refs['edit-modal'].show();
      this.file = ''
    },
    handleFileUpload(event) {
      this.file = event.target.files[0];
      if (!this.isExcel(this.file)) {
        this.file = '';
        this.doneAlert({ text: this.$t('Only supports upload .xlsx, .xls, .csv suffix files'), type: 'error' });
        return false
      }
    },
    isExcel(file) {
      return /\.(xlsx|xls|csv)$/.test(file.name)
    },
    uploadItems() {
      if (this.file === '' || !this.file.name) {
        this.doneAlert({ text: this.$t('addFileFirst'), type: 'warning' });
        return;
      }
      const formData = new FormData();
      formData.append('file', this.file);
      this.create({ url: 'employees/data-migration', data: formData })
      .then(() => {
        this.closeModal();
        this.doneAlert({ text: this.$t('savedSuccessfully') });
      });
    },
    downloadExcelSheet() {
      this.get({ url: 'employees/data-migration', responseType: 'arraybuffer' }).then((data) => {
        saveAs(new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }), 'Employees.xlsx');
      });
    },

    loadLookups() {
      this.get({ url: 'departments' }).then((data) => {
        this.departments = data;
      })
      .then(() => {
        this.get({ url: 'divisions' }).then((data) => {
        this.divisions = data;
      });
      })
      .then(() => {
        this.get({ url: 'jobs' }).then((data) => {
        this.jobs = data;
      });
      })
    },
    ResetSelection() {
      if (!this.showFilter) {
        this.filter.employeeCode = null;
        this.filter.employeeName = null;
        this.filter.departmentId = null;
        this.filter.divisionId = null;
        this.filter.jobId = null;
        this.filter.mobileNumber = null;
        this.getItems();
      }
    },
    printDocument() {
      this.hideActions = true;
      setTimeout(() => {
        this.pdfExport(this.$route.name);
        this.hideActions = false;
      }, 500);
    },
    pdfExport(name) {
      html2canvas(document.getElementById("mainTable")).then(function (canvas) {
        var imgData = canvas.toDataURL("image/png");
        var imgWidth = 210;
        var pageHeight = 295;
        var imgHeight = (canvas.height * imgWidth) / canvas.width;
        var heightLeft = imgHeight;

        var doc = new jsPDF("p", "mm");
        var position = 0;

        doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          doc.addPage();
          doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }
        doc.save(`${name}.pdf`);
      });
    },
  },
};
</script>

<style>
.filter-section {
  background-color: #f9f9f9;
  margin: 15px 0;
  padding: 25px 0 5px;
  border-radius: 7px;
  border-top-left-radius: 0;
  border: 2px solid #ad8884;
}

.btn-filter {
  margin-left: 14px;
  width: 157px;
  margin-bottom: -16px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
</style>
